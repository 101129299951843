import { get, post, put, del, download } from '../index'

/**
 * 分页获取数据模板
 * @param data
 * @returns {Promise<any>}
 */
export const dataEntList = data => {
  return get('/declare/data/ent/list', data).then(res => res)
}

/**
 * 分页获取申报底稿模板
 * @param data
 * @returns {Promise<any>}
 */
export const tmplTntList = data => {
  return get('/declare/tmpl/tnt/list', data).then(res => res)
}
/**
 * 添加申报底稿模板
 * @param data
 * @returns {Promise<any>}
 */
export const saveDeclareTntDeclareTmpl = data => {
  return post('/declare/tmpl/tnt', data).then(res => res)
}

/**
 * 设置申报底稿禁用状态
 * @param data
 * @returns {Promise<any | never>}
 */
export const changeStatus = data => {
  return put('/declare/status', data).then(res => res)
}


/**
 * 分页获取申报模板
 * @param data
 * @returns {Promise<any>}
 */
export const tmplEntList = data => {
  return get('/declare/tmpl/ent/list', data).then(res => res)
}

/**
 * 分页获取外部数据
 * @param data
 * @returns {Promise<any>}
 */
export const externalEntList = data => {
  return get('/declare/external/ent/list', data).then(res => res)
}

/**
 * 添加申报模板
 * @param data
 */
export const addDeclareEntDeclareTmpl = data => {
  return post('/declare/tmpl/ent', data).then(res => res)
}

/**
 * 添加申报模板
 * @param data
 */
export const addDeclareEntDeclareExternal = data => {
  return post('/declare/external/ent', data).then(res => res)
}

/**
 * 查询租户底稿模板
 * @param data
 * @returns {Promise<any | never>}
 */
export const getTntTmplName = data => {
  return get('/declare/tmpl/tnt/tntTmplName', data).then(res => res)
}

/**
 * 查询底稿模板
 * @param data
 * @returns {Promise<any | never>}
 */
export const allTntTmplList = data => {
  return get('/declare/tmpl/tnt/all', data).then(res => res)
}

/**
 * 获取申报底稿模板
 * @param data
 * @returns {Promise<any>}
 */
export const getDeclareTntDeclareTmpl = data => {
  return get('/declare/tmpl/tnt/list', data).then(res => res)
}

/**
 * 分页获取底稿调整记录
 * @param param
 * @returns {Promise<T>}
 */
export const listTmplHistory = param => {
  return get('/declare/log/list', param).then(res => res)
}

/**
 * 更新调整记录原因
 * @param data
 * @returns {Promise<T>}
 */
export const updateTmplHistory = data => {
  return put('/declare/log', data).then(res => res)
}

/**
 * 获取调整记录
 * @param param
 * @returns {Promise<T>}
 */
export const getLog = param => {
  return get('/declare/log', param).then(res => res)
}

/**
 * 根据底稿表格Id获取申报信息
 * @param id
 * @returns {Promise<T>}
 */
export const getDeclareByDraftTableId = id => {
  return get(`/declare/getDeclareByDraftTableId/${id}`).then(res => res)
}

export const selectDeclare = (id) => {
  return get(`/declare/${id}`).then(res => res)
}

/**
 * 生成申报表
 * @param param
 * @returns {Promise}
 */
export const buildDeclare = data => {
  return post('/declare/build', data).then(res => res)
}

/**
 * 重新生成底稿模版
 * @param data
 * @returns {Promise<T>}
 */
export const resetDeclareDetail = data => {
  return post('/declare/reset', data).then(res => res)
}

/**
 * 上传
 * @param data
 * @returns {Promise<T>}
 */
export const uploadDeclareTmpl = data => {
  return post('/declare/tmpl/upload', data).then(res => res)
}

/**
 * 上传外部数据
 * @param data
 * @returns {Promise<T>}
 */
export const uploadDeclareExternal = data => {
    return post('/declare/external/upload', data).then(res => res)
}

/**
 * 申报信息删除申报模版/底稿模版
 * @param id
 * @returns {Promise<T>}
 */
export const deleteDeclareTmpl = id => {
  return del(`/declare/deleteTmpl/${id}`).then(res => res)
}

/**
 * 申报信息删除申报模版/底稿模版
 * @param id
 * @returns {Promise<T>}
 */
export const deleteDeclareExternal = id => {
    return del(`/declare/deleteExternal/${id}`).then(res => res)
}


export const declareSubmit = id => {
  return post(`/declare/declare/${id}`).then(res => res)
}

// 划款
export const declareHkSubmit = id => {
  return post(`/declare/declarehk/${id}`).then(res => res)
}

export const declareSync = id => {
  return get(`/declare/sync/${id}`).then(res => res)
}

/**
 * 提交审批
 * @param id
 * @returns {Promise<T>}
 */
export const submitApprovalDeclareTmpl = data => {
  return put(`/declare/approval`, data).then(res => res)
}
/**
 * 保存审批意见
 * @param id
 * @returns {Promise<T>}
 */
export const saveApprovalOpinion = data => {
  return put(`/declare/approvalOpinion`, data).then(res => res)
}

/**
 * 审核日志
 * @param data
 * @returns {Promise<any>}
 */
export const approvalLogList = data => {
  return get('/declare/approval/log/list', data).then(res => res)
}


/**
 * 保存底稿
 * @param data
 * @returns {Promise<T>}
 */
export const saveSheets = data => {
  return post('/declare/saveSheets', data).then(res => res)
}

/**
 * 保存操作记录
 * @param data
 * @returns {Promise<T>}
 */
export const saveLogs = data => {
  return post('/declare/log/saveLogs', data).then(res => res)
}

/**
 * 下载完税凭证文件
 * @param data
 * @returns {Promise<*>}
 */
export const downloadPdf = data => {
  return download('/declare/downloadPdf', data).then(res => res)
}

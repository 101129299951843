import { get, post } from '../index'

/**
 * 分页获取数据模板
 * @param data
 * @returns {Promise<any>}
 */
export const getDeclareSettingTree = (params) => {
  return get('/declare/setting/list', params).then(res => res)
}

/**
 * 根据组织id获取申报设置信息
 * @param orgId
 * @returns {Promise<any | never>}
 */
export const getDeclareSetting = (orgId) => {
  return get(`/declare/setting/${orgId}`).then(res => res)
}

/**
 * 保存配置
 * @param data
 * @returns {Promise<any>}
 */
export const saveDeclareSetting = data => {
  return post('/declare/setting', data).then(res => res)
}

/**
 * 组织校验
 * @param {*} data
 */
export const checkUniqueZtbh = (data) => {
  return post('/declare/setting/unique/ztbh', data).then(res => res)
}